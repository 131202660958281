import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Paper, Typography, Grid, Avatar, List, ListItem, Alert, ListItemIcon } from '@mui/material';
import { Favorite, ArrowUpward, ArrowDownward, BuildCircle } from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';

const StreamerDashboard = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [followerCount, setFollowerCount] = useState(0);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [recentActivity, setRecentActivity] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [recentFollowers, setRecentFollowers] = useState([]);

  useEffect(() => {
    const fetchStreamerData = async () => {
      try {
        const userResponse = await axios.get('/api/twitch/user', { withCredentials: true });
        setUserInfo(userResponse.data);

        const followersCountResponse = await axios.get('/api/twitch/followers/count', { withCredentials: true });
        setFollowerCount(followersCountResponse.data.total);

        const subscribersResponse = await axios.get('/api/twitch/subscribers/count', { withCredentials: true });
        setSubscriberCount(subscribersResponse.data.total);

        const recentActivityResponse = await axios.get('/api/recent-activity', { withCredentials: true });
        setRecentActivity(recentActivityResponse.data.data);

        const followersResponse = await axios.get('/api/twitch/followers/recent', { withCredentials: true });
        setRecentFollowers(followersResponse.data);

        const notificationsResponse = await axios.get('/api/notifications', { withCredentials: true });
        if (Array.isArray(notificationsResponse.data.data)) {
          setNotifications(notificationsResponse.data.data);
        } else {
          setNotifications([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchStreamerData();
  }, []);

  const DigIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="currentColor"
      style={{ marginRight: '0.5em', color: '#ff7e04' }}
    >
      <g fill="none">
        <path d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z"/>
        <path fill="currentColor" d="M16.243 3.515a1 1 0 0 1 1.414 0l1.41 1.41l.004.004l.005.004l1.41 1.41a1 1 0 0 1-1.415 1.414l-.707-.707l-4.95 4.95l1.414 1.414a2 2 0 0 1 0 2.829l-2.494 2.494a5 5 0 0 1-5.117 1.208l-.949-.316a3 3 0 0 1-1.897-1.898l-.316-.948a5 5 0 0 1 1.208-5.117l2.494-2.495a2 2 0 0 1 2.829 0L12 10.586l4.95-4.95l-.707-.707a1 1 0 0 1 0-1.414"/>
      </g>
    </svg>
  );

  const getIcon = (actionType) => {
    switch (actionType) {
      case 'deposit':
        return <ArrowDownward sx={{ color: 'green', marginRight: '4px' }} />;
      case 'withdraw':
        return <ArrowUpward sx={{ color: 'red', marginRight: '4px' }} />;
      case 'dig':
        return <DigIcon />;
      case 'follow':
        return <Favorite sx={{ color: '#ff7e04', marginRight: '4px' }} />;
      default:
        return null;
    }
  };

  const formatDate = (date) => formatDistanceToNow(new Date(date), { addSuffix: true });

  const getAssetText = (count) => (count === 1 ? 'asset' : 'assets');

  // Notifications
  const renderNotificationRow = (notification, index) => {
    const getBackgroundColor = (notificationType) => {
      switch (notificationType) {
        case 'info':
          return '#e0f7fa'; // Light cyan for info
        case 'warning':
          return '#fff3e0'; // Light orange for warning
        case 'error':
          return '#ffebee'; // Light red for error
        case 'success':
          return '#e8f5e9'; // Light green for success
        default:
          return '#fff'; // Default white for unspecified
      }
    };
  
    return (
      <Alert
        key={index}
        severity={notification.notification_type}
        sx={{
          display: 'flex',
          alignItems: 'center',  // Align all items in the row to be vertically centered
          backgroundColor: getBackgroundColor(notification.notification_type),
          marginBottom: '6px',
          borderRadius: 1,
          color: '#000',
          padding: '8px',  // Add controlled padding around the content
          minHeight: 'auto',
        }}
      >
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '4px', display: 'flex', alignItems: 'center' }}>  {/* Ensure icon is centered */}
          {getIcon(notification.notification_type)}
        </ListItemIcon>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1 }}>  {/* Center the text and make it grow to fit */}
          <Typography variant="subtitle1" fontWeight="bold" sx={{ margin: 0, lineHeight: 1.2 }}>
            {notification.title}
          </Typography>
          <Typography variant="body2" sx={{ margin: 0, lineHeight: 1.2 }}>
            {notification.message}
          </Typography>
        </Box>
      </Alert>
    );
  };

  // Recent Activity
  const renderActivityRow = (activity, index) => (
      <ListItem
        key={index}
      sx={{ p: 1, backgroundColor: '#fff', marginBottom: '6px', borderRadius: 1, color: '#000' }}
      >
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '4px' }}>{getIcon(activity.action_type)}</ListItemIcon>
        <Typography>
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            {activity.action_type === 'deposit' ? 'Deposited' : activity.action_type === 'withdraw' ? 'Withdrew' : 'Dug up'} {activity.action_count} {getAssetText(activity.action_count)}
          </Box>{' '}
          <Box component="span" sx={{ color: 'text.secondary', marginLeft: 0.5 }}>
            {formatDate(activity.action_date)}
          </Box>
        </Typography>
      </ListItem>
  );

  // Recent Followers
  const renderFollowerRow = (follower, index) => (
      <ListItem
        key={index}
      sx={{ p: 1, backgroundColor: '#fff', marginBottom: '6px', borderRadius: 1, color: '#000' }}
      >
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '4px' }}>{getIcon('follow')}</ListItemIcon>
        <Typography>
          <Box component="span" sx={{ fontWeight: 'bold' }}>{follower.user_name}</Box>{' '}
          <Box component="span" sx={{ color: 'text.secondary', marginLeft: 0.5 }}>
            {formatDate(follower.timestamp)}
          </Box>
        </Typography>
      </ListItem>
  );

  if (!userInfo) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ padding: 3, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      {/* Top Section */}
      <Box sx={{ padding: '12px 0', marginBottom: 2, color: '#fff', flex: '0 0 13%' }}>
        <Grid container alignItems="center" spacing={4}>
          {/* Streamer Info */}
          <Grid item xs={12} md={4}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar src={userInfo.profile_image_url} sx={{ width: 80, height: 80 }} />
              </Grid>
              <Grid item>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  Hello, {userInfo.display_name}!
                </Typography>
                <Typography variant="body1">Welcome to your Stream Dashboard</Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Counts Section (Followers, Subscribers) */}
          <Grid item xs={12} md={8}>
            <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <Paper sx={{ padding: 2, backgroundColor: '#424867', color: '#fff', textAlign: 'center' }}>
                  <Typography variant="h6">Followers</Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      color: '#ff7e04',
                      fontSize: { xs: '1.5rem', md: '2.5rem' },
                    }}
                  >
                    {Number(followerCount).toLocaleString()}
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper sx={{ padding: 2, backgroundColor: '#424867', color: '#fff', textAlign: 'center' }}>
                  <Typography variant="h6">Subscribers</Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      color: '#ff7e04',
                      fontSize: { xs: '1.5rem', md: '2.5rem' },
                    }}
                  >
                    {Number(subscriberCount).toLocaleString()}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2} sx={{ flex: 1, height: '100%' }}>

        {/* Recent Notifications Section */}
        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', maxHeight: '65vh' }}>
          <Paper
            sx={{
              flexGrow: 1,
              padding: 0,
              backgroundColor: '#424867',
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
            }}>
            <Box sx={{ 
              padding: '16px 0px 0px 16px', 
              position: 'sticky', 
              backgroundColor: '#424867', 
              zIndex: 10 
            }}>
              <Typography variant="h6" sx={{ marginBottom: 1 }}>
                Recent Notifications
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '0px 8px 8px 8px' }}>
              <List>
                {notifications.map((notification, index) => renderNotificationRow(notification, index))}
              </List>
            </Box>
          </Paper>
        </Grid>

        {/* Recent Scurvy Activity Section */}
        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', maxHeight: '65vh' }}>
          <Paper
            sx={{
              flexGrow: 1,
              padding: 0,
              backgroundColor: '#424867',
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
            }}>
            <Box sx={{ 
              padding: '16px 0px 0px 16px', 
              position: 'sticky', 
              backgroundColor: '#424867', 
              zIndex: 10 
            }}>
              <Typography variant="h6" sx={{ marginBottom: 1 }}>
                Recent Scurvy Activity
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '0px 8px 8px 8px' }}>
              <List>
                {recentActivity.map((activity, index) => renderActivityRow(activity, index))}
              </List>
            </Box>
          </Paper>
        </Grid>

        {/* Recent Followers Section */}
        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', maxHeight: '65vh' }}>
          <Paper
            sx={{
              flexGrow: 1,
              padding: 0,
              backgroundColor: '#424867',
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
            }}>
            <Box sx={{ 
              padding: '16px 0px 0px 16px', 
              position: 'sticky', 
              backgroundColor: '#424867', 
              zIndex: 10 
            }}>
              <Typography variant="h6" sx={{ marginBottom: 1 }}>
                Recent Followers
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '0px 8px 8px 8px' }}>
              <List>
                {recentFollowers.map((follower, index) => renderFollowerRow(follower, index))}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StreamerDashboard;