import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Switch, 
  Divider, 
  Tab, 
  Tabs, 
  Grid, 
  TextField,
  CircularProgress,
  Button,
  FormControlLabel,
  InputAdornment
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

// Normalizes the setting value based on its data type
const normaliseSettingValue = (value, dataType) => {
  switch (dataType) {
    case 'boolean':
      return value === 'true' || value === '1' || value === true ? 'true' : 'false';
    case 'int':
      return value !== null ? parseInt(value, 10) : '';
    case 'float':
      return value !== null ? parseFloat(value) : '';
    case 'percent':
      return value !== null ? parseFloat(value) * 100 : '';
    case 'string':
    default:
      return value === null ? '' : value;
  }
};

// Function to compare values considering type conversions
const compareValues = (currentValue, initialValue, dataType) => {
  const normalizedCurrent = normaliseSettingValue(currentValue, dataType);
  const normalizedInitial = normaliseSettingValue(initialValue, dataType);
  return normalizedCurrent === normalizedInitial;
};

// Generate validation rules based on data type and dependencies
const getValidationRules = (setting, dependencies) => {
  const isDependentDisabled = dependencies.some((dep) => dep === false);

  switch (setting.setting_data_type) {
    case 'boolean':
      return {}; // No specific validation for booleans
    case 'int':
      return isDependentDisabled
        ? {} // No validation if dependent is disabled
        : {
            required: `${setting.setting_label} is required`,
            valueAsNumber: true,
            validate: (value) => Number.isInteger(value) || `${setting.setting_label} must be an integer`,
          };
    case 'float':
      return isDependentDisabled
        ? {}
        : {
            required: `${setting.setting_label} is required`,
            valueAsNumber: true,
            validate: (value) => !isNaN(value) || `${setting.setting_label} must be a valid number`,
          };
    case 'percent':
      return isDependentDisabled
        ? {}
        : {
            required: `${setting.setting_label} is required`,
            valueAsNumber: true,
            validate: (value) => !isNaN(value) && value >= 0 && value <= 100 || `${setting.setting_label} must be a valid percentage`,
          };
    case 'string':
    default:
      return isDependentDisabled
        ? {}
        : {
            required: `${setting.setting_label} is required`,
          };
  }
};

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [settingsData, setSettingsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [formValues, setFormValues] = useState({}); // Store form values for each tab
  const [formKey, setFormKey] = useState(0);  // Key to force re-render when switching tabs
  const { control, reset, setValue, getValues, formState: { errors } } = useForm({
    defaultValues: {}
  });
  const tabContainerRef = useRef(null);

  // Fetch settings and store initial values
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch('/api/channel-settings?setting_type=all_settings');
        const result = await response.json();

        if (result.success) {
          const normalisedSettings = result.data.map(setting => ({
            ...setting,
            setting_value: normaliseSettingValue(setting.setting_value, setting.setting_data_type),
          }));
          setSettingsData(normalisedSettings);

          // Set initial values for comparison
          const defaultValues = normalisedSettings.reduce((acc, setting) => {
            acc[setting.setting_key] = setting.setting_value;
            return acc;
          }, {});
          setInitialValues(defaultValues); // Store the initial values for comparison
          reset(defaultValues); // Reset the form with the fetched values
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [reset]);

  // Compare if a value has changed using compareValues
  const isChanged = (name, value, dataType) => {
    return !compareValues(value, initialValues[name], dataType);
    };
  
  // Post changes if value has changed
  const postChangesLive = async (modifiedSettings) => {
    try {
      const convertedSettings = modifiedSettings.map(setting => {
        let { setting_value, setting_data_type } = setting;

        // Send None if the value is empty (for nullable fields)
        if (setting_value === '') {
          setting_value = null;
        }

        // Convert percentage fields back to decimal before posting
        if (setting_data_type === 'percent' && setting_value !== null) {
          setting_value = parseFloat(setting_value) / 100;
        }

        // Send true/false for boolean fields instead of 1/0
        if (setting_data_type === 'boolean') {
          setting_value = setting_value ? 'true' : 'false';
        }

        return { ...setting, setting_value };
      });
  
        const response = await fetch('/api/channel-settings', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ settings: convertedSettings }),
        });
  
        if (response.ok) {
          // After successful update, reset the initial values to reflect the updated state
          const updatedValues = getValues();
          setInitialValues(updatedValues); // Store updated values as the new initial state
        }
      } catch (error) {
        console.error('Error posting changes:', error);
      }
    };
  
    // Handle input blur and check if the value has changed before posting
  const handleInputBlur = (name, value, dataType) => {
    if (isChanged(name, value, dataType)) {
      postChangesLive([{ setting_key: name, setting_value: value, setting_data_type: dataType }]);
      }
    };

  // Handle toggle changes (for switches) and post if changed
  const handleToggleChange = (name, value, dataType) => {
    if (isChanged(name, value, dataType)) {
    setSettingsData((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) => {
          // Handle dependent field logic (e.g., disabling other fields)
        if (name === 'chat_bot' && !value) {
          const dependentKeys = [
            'chat_follow_alert',
            'chat_subscription_alert',
            'chat_cheer_alert',
            'chat_raid_alert',
            'chat_stream_online',
            'chat_stream_offline',
            'chat_chest',
          ];
          dependentKeys.forEach((key) => setValue(key, false));
        }

          // Ensure toggling chest_command and chest_math appropriately
        if (name === 'chat_chest_command' && value) {
          setValue('chat_chest_math', false);
        }
        if (name === 'chat_chest_math' && value) {
          setValue('chat_chest_command', false);
        }

        return setting;
      });

      const modifiedSettings = [
        { setting_key: name, setting_value: value, setting_data_type: dataType },
      ];

      // If chest command or math is being toggled, ensure both values are posted
      if (name === 'chat_chest_command' || name === 'chat_chest_math') {
          // Update the form view for both fields
          setValue('chat_chest_math', name === 'chat_chest_command' ? false : value);
          setValue('chat_chest_command', name === 'chat_chest_math' ? false : value);

          // Ensure both settings are posted to the server
        modifiedSettings.push({
          setting_key: name === 'chat_chest_command' ? 'chat_chest_math' : 'chat_chest_command',
          setting_value: !value,
          setting_data_type: dataType
        });
      }

      postChangesLive(modifiedSettings);
      return updatedSettings;
    });
    }
  };
  
 // Handle resetting only the fields in the current tab to their default values
const handleReset = () => {
  if (settingsData) {
      const currentTabSettings = settingsData.filter(setting => setting.setting_type === settingTypes[activeTab]);
      const resetValues = currentTabSettings.reduce((acc, setting) => {
      acc[setting.setting_key] = normaliseSettingValue(setting.default_value, setting.setting_data_type);
      return acc;
    }, {});

      // Store values for non-current tabs
      const otherTabValues = getValues();
      const nonCurrentTabValues = Object.keys(otherTabValues)
        .filter(key => !Object.keys(resetValues).includes(key))
        .reduce((acc, key) => {
          acc[key] = otherTabValues[key];
          return acc;
        }, {});

      // Store form values for other tabs
      setFormValues(prev => ({ ...prev, ...nonCurrentTabValues }));

      reset({ ...resetValues, ...nonCurrentTabValues }); // Reset the form with the default values for the current tab only
      postChangesLive(Object.keys(resetValues).map(key => ({ setting_key: key, setting_value: resetValues[key] })));
      
      // Update initial values only for the current tab
      setInitialValues(prev => ({ ...prev, ...resetValues }));
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);

    // Restore form values for the tab being switched to
    const currentValues = getValues();
    const newTabValues = formValues;
    reset({ ...currentValues, ...newTabValues });  // Merge current and stored values
    setFormKey(prevKey => prevKey + 1);  // Force a re-render when switching tabs
  };

  // Native swipe functionality for mobile tabs
  useEffect(() => {
    const handleSwipe = (event) => {
      const touchStartX = event.changedTouches[0].screenX;

      const handleSwipeEnd = (endEvent) => {
        const touchEndX = endEvent.changedTouches[0].screenX;

        if (touchStartX - touchEndX > 50) {
          setActiveTab((prev) => Math.min(prev + 1, settingTypes.length - 1));
        } else if (touchEndX - touchStartX > 50) {
          setActiveTab((prev) => Math.max(prev - 1, 0));
        }

        tabContainerRef.current.removeEventListener('touchend', handleSwipeEnd);
      };

      tabContainerRef.current.addEventListener('touchend', handleSwipeEnd);
    };

    if (tabContainerRef.current) {
      tabContainerRef.current.addEventListener('touchstart', handleSwipe);
    }

    return () => {
      if (tabContainerRef.current) {
        tabContainerRef.current.removeEventListener('touchstart', handleSwipe);
      }
    };
  }, [activeTab]);

  if (loading) {
    return (
      <Box sx={{ padding: '16px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!settingsData) {
    return (
      <Box sx={{ padding: '16px' }}>
        <Typography variant="h6" color="error">Failed to load settings</Typography>
      </Box>
    );
  }

  const settingTypes = [...new Set(settingsData.map((setting) => setting.setting_type))];

  return (
    <Box sx={{ padding: '16px' }} ref={tabContainerRef} key={formKey}>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>

      <Box
        sx={{
          maxWidth: '90%',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          '&::-webkit-scrollbar': { display: 'none' },
          '@media (max-width: 600px)': {
            width: '100%',
            touchAction: 'pan-x',
          },
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="inherit"
          sx={{
            '& .MuiTab-root': {
              color: '#FFFFFF',
            },
            '& .Mui-selected': {
              color: '#ff7e04',
            },
          }}
        >
          {settingTypes.map((type, index) => (
            <Tab label={type.charAt(0).toUpperCase() + type.slice(1)} key={type} />
          ))}
        </Tabs>
      </Box>

      <Divider sx={{ marginY: 2 }} />

      {settingTypes.map((type, index) =>
        activeTab === index ? (
          <Box sx={{ padding: 0 }} key={type}>
              <Grid container spacing={2}>
                {settingsData
                .filter((setting) => setting.setting_type === settingTypes[activeTab])
                  .map((setting) => (
                    <Grid item xs={12} key={setting.setting_key}>
                      {setting.setting_data_type === 'boolean' ? (
                        <Controller
                          name={setting.setting_key}
                          control={control}
                        rules={getValidationRules(setting, [true])} // Use the validation rules for booleans
                          render={({ field }) => (
                            <FormControlLabel
                              labelPlacement="start"
                              control={
                                <Switch
                                  {...field}
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e);
                                  handleToggleChange(setting.setting_key, e.target.checked, setting.setting_data_type);
                                  }}
                                  sx={{
                                    '& .MuiSwitch-track': {
                                      backgroundColor: '#ff7e04',
                                    },
                                  }}
                                />
                              }
                              label={setting.setting_label}
                            disabled={setting.disabled}
                            />
                          )}
                        />
                      ) : setting.setting_data_type === 'percent' ? (
                        <Controller
                          name={setting.setting_key}
                          control={control}
                          rules={getValidationRules(setting, [true])}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label={setting.setting_label}
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    sx={{
                                      color: 'inherit', // Match the input color
                                      marginRight: '-0.5rem',
                                    }}
                                  >
                                    %
                                  </InputAdornment>
                                ),
                                inputMode: 'decimal', // Ensure the input is numeric
                              }}
                              error={!!errors[setting.setting_key]}
                              helperText={errors[setting.setting_key]?.message}
                              disabled={setting.disabled}
                              onBlur={(e) => handleInputBlur(setting.setting_key, e.target.value, setting.setting_data_type)}
                            />
                          )}
                        />
                      ) : (
                        <Controller
                          name={setting.setting_key}
                          control={control}
                        rules={getValidationRules(setting, [true])} // Apply the validation rules for other types
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label={setting.setting_label}
                              type={setting.setting_data_type === 'int' ? 'number' : 'text'}
                              error={!!errors[setting.setting_key]}
                              helperText={errors[setting.setting_key]?.message}
                            disabled={setting.disabled}
                            onBlur={(e) => handleInputBlur(setting.setting_key, e.target.value, setting.setting_data_type)}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  ))}
              </Grid>
          </Box>
        ) : null
      )}

              <Box
                sx={{
                  width: '35%',
                  marginTop: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  '@media (max-width: 600px)': {
                    width: '90%',
                  },
                }}
              >
              <Button
                variant="contained"
                fullWidth
                onClick={handleReset}
                sx={{ flex: 1, marginLeft: 1 }}
              >
                Reset to Default
              </Button>
            </Box>
    </Box>
  );
};

export default Settings;
