import React from 'react';
import { styled } from '@mui/system';

const Container = styled('div')({
  padding: '20px',
  color: '#FFFFFF',
  backgroundColor: '#32384B',
  textAlign: 'center',
  boxSizing: 'border-box',
  width: '100%',
  flex: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const Title = styled('h1')({
  fontSize: '3rem',
  marginBottom: '20px',
});

const Subtitle = styled('h2')({
  fontSize: '2rem',
  marginBottom: '20px',
});

const Text = styled('p')({
  fontSize: '1.2rem',
  marginBottom: '20px',
  width: '100%',
  textAlign: 'center',
});

const List = styled('div')({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

const ListItem = styled('div')({
  fontSize: '1.2rem',
  marginVertical: '8px',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  textAlign: 'center',
});

const Strong = styled('strong')({
  fontWeight: 'bold',
});

const HomePage = () => {
  return (
    <Container>
      <Subtitle>Welcome to Scurvy Bot - Your Ultimate Stream Loyalty Program</Subtitle>

      <Text><Strong>For Streamers:</Strong> Elevate your streaming experience with Scurvy Bot's innovative loyalty program. Seamlessly create and manage custom pools of Wax tokens and NFTs directly on our platform. Deposit tokens and NFTs to reward your viewers for their engagement — whether they’re watching, chatting, donating, subscribing, or participating in your streams through fun on stream minigames and giveaways.</Text>
      
      <Text><Strong>For Viewers:</Strong> Maximize your viewing experience by earning loyalty tokens just for supporting your favorite streamers. Accumulate tokens by engaging with streams, then redeem these tokens for NFTs or tokens on the Wax blockchain from the streamer's pools.</Text>
      
      <Text><Strong>How does it work?</Strong></Text>
      <List>
        <ListItem>
          <span><Strong>Earn Tokens:</Strong> Engage with streams to earn loyalty tokens. Actions like watching, chatting, spending bits and subscribing contribute to your token balance.</span>
        </ListItem>
        <ListItem>
          <span><Strong>Redeem Tokens:</Strong> Use your loyalty tokens to claim real Wax blockchain tokens from the streamer's custom pools and redemption rates.</span>
        </ListItem>
        <ListItem>
          <span><Strong>Dig for NFTs:</Strong> Use your loyalty tokens to dig for a chance to find an NFT on the Wax blockchain from the streamer's custom pool and dig chance.</span>
        </ListItem>
        <ListItem>
          <span><Strong>Stay Informed:</Strong> Our advanced loyalty bot keeps you updated on your progress, balances, and redemption options through chat notifications and commands.</span>
        </ListItem>
        <ListItem>
          <span><Strong>Seamless Integration:</Strong> Powered by the Twitch API and Wax API, Scurvy Bot offers a seamless and integrated experience for both streamers and viewers. Effortlessly track and redeem your loyalty tokens within the Twitch ecosystem.</span>
        </ListItem>
      </List>

      <Text>Login with your Twitch account and start enhancing your streaming journey today. Experience the future of stream loyalty programs with Scurvy Bot.</Text>
    </Container>
  );
};

export default HomePage;
