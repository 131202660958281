import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../components/AuthContext';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

const ChatOverlay = () => {
  const { authState } = useAuth(); // Access the auth object
  const [ws, setWs] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [badgeMapping, setBadgeMapping] = useState({});
  const chatMessagesRef = useRef(null); // To track the chat messages container

  const theme = useTheme();

  useEffect(() => {
    scrollToBottom(chatMessagesRef);
  }, [chatMessages]);

  // Fetch badge mappings from API
  useEffect(() => {
    const fetchBadgeMappings = async () => {
      try {
        const response = await axios.get('/api/badges/chat');
        const badgeData = response.data;
        const badgeMap = badgeData.reduce((acc, badge) => {
          acc[`${badge.identifier}`] = badge.image_url;
          return acc;
        }, {});
        setBadgeMapping(badgeMap);
      } catch (error) {
        console.error('Error fetching badge mappings:', error);
      }
    };

    fetchBadgeMappings();
  }, []);

  // WebSocket connection and message handling
  useEffect(() => {
    if (!authState.isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }

    const socket = new WebSocket('wss://ws.scurvy.dasker.co.uk:50443');

    socket.onopen = () => {
      const credentials = { type: 'auth', session_key: authState.session_key };
      socket.send(JSON.stringify(credentials)); // Send the session_key for authentication
    };

    socket.onmessage = (event) => {
      let rawMessage = event.data;
      rawMessage = rawMessage.replace(/[\u0000-\u001F\u007F-\u009F]/g, ' ').trim();
      const parsedMessage = JSON.parse(rawMessage);

      if (parsedMessage.type === 'chat') {
        addChatMessage(parsedMessage);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error.message);
    };

    socket.onclose = (event) => {
      console.log(`Connection closed: ${event.reason}`);
    };

    setWs(socket);

    return () => {
      socket.close();
    };
  }, [authState]);

  const scrollToBottom = (ref) => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight - ref.current.clientHeight;
    }
  };

  const addChatMessage = (message) => {
    setChatMessages((prev) => {
      const newMessages = [...prev, message];
      return newMessages.length > 50 ? newMessages.slice(newMessages.length - 50) : newMessages;
    });
  };

  const parseEmotes = (text, emotes) => {
    if (!emotes) return text;

    const emoteReplacements = emotes.split('/').map(emoteData => {
      const [emoteId, positions] = emoteData.split(':');
      return positions.split(',').map(position => {
        const [start, end] = position.split('-').map(Number);
        return { start, end, emoteUrl: `https://static-cdn.jtvnw.net/emoticons/v2/${emoteId}/default/dark/1.0` };
      });
    }).flat();

    emoteReplacements.sort((a, b) => b.start - a.start);

    emoteReplacements.forEach(({ start, end, emoteUrl }) => {
      const emoteTag = `<img src="${emoteUrl}" alt="emote" style="width: 1.5rem; vertical-align: middle;" />`;
      text = text.slice(0, start) + emoteTag + text.slice(end + 1);
    });

    return text;
  };

  const getBadgeUrl = (badge) => {
    return badgeMapping[badge] || null;
  };

  const renderChatMessage = (message) => {
    const { username, text, badges, color, emotes } = message;

    const badgeElements = badges
      ? badges.split(',').map((badge, index) => {
          const badgeUrl = getBadgeUrl(badge);
          return badgeUrl ? (
            <img 
              key={index} 
              src={badgeUrl} 
              alt={badge} 
              style={{
                width: '1.5rem', 
                height: '1.5rem', 
                marginRight: '0.3rem',
                verticalAlign: 'middle' // Center align badges with text
              }} 
            />
          ) : null;
        })
      : null;

    const messageWithEmotes = { __html: parseEmotes(text, emotes) };

    return (
      <div key={message.timestamp} style={{ marginBottom: '1rem', lineHeight: '1.5rem' }}>
        {badgeElements}
        <strong style={{ color, fontSize: '1.2rem', verticalAlign: 'middle' }}>{username}</strong>
        <span>: </span>
        <span dangerouslySetInnerHTML={messageWithEmotes} style={{ fontSize: '1.2rem', verticalAlign: 'middle' }} />
      </div>
    );
  };

  return (
    <div
      ref={chatMessagesRef}
      style={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundColor: theme.palette.background.overlay,
      }}
    >
      <div style={{ padding: '1rem', fontSize: '1.2rem', color: theme.palette.text.primary, fontFamily: 'Arial' }}>
        {chatMessages.map(renderChatMessage)}
      </div>
    </div>
  );
};

export default ChatOverlay;
