import React, { useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/system';
import { useLocation } from 'react-router-dom';
import Menu from './Menu';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 125px)',
});

const MainContainer = styled('div')({
  display: 'flex',
  flex: 1,
});

const Sidebar = styled('div')(({ theme, isCollapsed }) => ({
  width: isCollapsed ? 0 : '200px',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  flexShrink: 0,
  boxSizing: 'border-box',
  zIndex: 2,
  position: 'fixed',
  top: theme.spacing(11), // 88px
  bottom: theme.spacing(4.625), // 37px
  overflowY: 'auto',
  transition: 'width 0.3s',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  padding: 0,
}));

const ContentWrapper = styled('div')(({ theme, isCollapsed }) => ({
  flex: 1,
  marginLeft: isCollapsed ? '16px' : '216px', // 16px or 216px, depending on sidebar state
  transition: 'margin-left 0.3s',
  overflow: 'hidden',
  padding: '8px',
}));

const Content = styled('div')(({ theme, showScrollbar }) => ({
  flex: 1,
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  boxSizing: 'border-box',
  overflowY: showScrollbar ? 'auto' : 'hidden',
}));

const ToggleButton = styled('button')(({ theme, isCollapsed }) => ({
  position: 'fixed',
  top: '50%',
  left: isCollapsed ? 0 : '200px',
  zIndex: 3,
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: 'none',
  cursor: 'pointer',
  padding: theme.spacing(0.625), // 5px
  borderTopRightRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  transition: 'left 0.3s, border-radius 0.3s',
}));

const streamerMenuItems = [
  { label: 'Streamer Dashboard', link: '/streamer-dashboard' },
  { label: 'View Dig Pool', link: '/streamer-dashboard/nft-pool' },
  { label: 'NFT Transactions', link: '/streamer-dashboard/nft-transactions' },
  { label: 'Create Token Pool', link: '/streamer-dashboard/create-pool' },
  { label: 'View Token Pools', link: '/streamer-dashboard/token-pools' },
  { label: 'Token Transactions', link: '/streamer-dashboard/token-transactions' },
  { label: 'Commands', link: '/streamer-dashboard/commands' },
  { label: 'Timed Messages', link: '/streamer-dashboard/timed-messages' },
  { label: 'Leaderboard', link: '/streamer-dashboard/leaderboard' },
  { label: 'Settings', link: '/streamer-dashboard/settings' },
];

const viewerMenuItems = [
  { label: 'Balances', link: '/viewer-dashboard/balances' },
  { label: 'Transactions', link: '/viewer-dashboard/transactions' },
];

const DashboardLayout = ({ children }) => {
  const theme = useTheme();
  const location = useLocation();
  const isStreamerDashboard = location.pathname.startsWith('/streamer-dashboard');
  const menuItems = isStreamerDashboard ? streamerMenuItems : viewerMenuItems;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const contentRef = useRef();

  const toggleSidebar = () => {
    setIsCollapsed(prev => !prev);
  };

  useEffect(() => {
    const updateMinHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const viewportHeight = window.innerHeight;
        const newMinHeight = contentHeight > viewportHeight - theme.spacing(21.25) // 170px
          ? `${contentHeight}px`
          : 'calc(100vh - 128px)';
        setShowScrollbar(contentHeight > viewportHeight - theme.spacing(21.25));
      }
    };

    updateMinHeight();
    window.addEventListener('resize', updateMinHeight);
    const observer = new MutationObserver(updateMinHeight);
    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true });
    }

    return () => {
      window.removeEventListener('resize', updateMinHeight);
      if (contentRef.current) {
        observer.disconnect();
      }
    };
  }, [children, theme]);

  return (
    <DashboardLayoutContainer>
      <MainContainer>
        <Sidebar theme={theme} isCollapsed={isCollapsed}>
          <Menu items={menuItems} />
        </Sidebar>
        <ContentWrapper theme={theme} isCollapsed={isCollapsed}>
          <ToggleButton theme={theme} onClick={toggleSidebar} isCollapsed={isCollapsed}>
            {isCollapsed ? <ArrowForwardIos /> : <ArrowBackIos />}
          </ToggleButton>
          <Content ref={contentRef} showScrollbar={showScrollbar} theme={theme}>
            {children}
          </Content>
        </ContentWrapper>
      </MainContainer>
    </DashboardLayoutContainer>
  );
};

export default DashboardLayout;
